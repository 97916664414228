<template>
  <div class="qiancun">
    <div class="qiancun-con">
      <div class="title">
        <div class="two">“千村美景”摄影大赛征稿启事</div>
      </div>
      <p>千村美景大家拍，无边风光入画来。</p>

      <p>
        为全面展示江苏产业兴旺、生态宜居、治理有效、富裕生活等方面的新成效、新面貌，展示江苏美丽乡村的共富成果，丰富视觉江苏网图片资源，推进江苏视觉形象工程建设，“视觉江苏”学习强国号“千村美景”摄影大赛，征稿启事如下：
      </p>
      <div class="name">一、征稿时间</div>
      <p>2022年1月1日-2024年12月31日</p>
      <div class="name">二、活动内容</div>
      <p>面向全省特约摄影师，征集全省1000个村庄的美景照片，同时集中反映我省乡村产业发展、生态建设、乡风治理、农民生活等方面的新成效、新面貌。</p>
      <div class="name">三、来稿要求</div>
      <p>以行政村（自然村、农村社区亦可）为单位拍摄，每村照片10-20幅，有航拍和反映村标志性景点、建筑或村头风貌的照片，风光照片应占一半以上，其余照片可包括以下几类：</p>
      <p>1.产业发展类：果树种植、拱棚蔬菜、规模种植、特色养殖、生态庄园等；</p>
      <p>2.生态建设类：村容新貌、基础设施、环境保护、风电景观、生态文化等。</p>
      <p>3.乡风治理类：红色遗址、民俗风情、人文景观、卫生教育、干部驻村、村务管理等。</p>
      <p>4.幸福生活类：农耕劳作、农业收获、农村集贸、农家院落、文化娱乐、精神面貌等。</p>
      <div class="name">四、特别说明</div>
      <p>来稿用途包括且不限于以下几种：</p>
      <p>1.视觉江苏网专题展示；</p>
      <p>2.新华日报要闻版或专版发表；</p>
      <p>3.新华报业传媒集团所属各报网微端抖发表；</p>
      <p>4.视觉江苏学习强国号推送，优质图片以村庄为单位单条推送；</p>
      <p>5.省级重大主题展览中展出。</p>
      <div class="name">五、奖项设置</div>
      <p>经典图片大奖3个，奖金3000元（个人所得税自理）；</p>
      <p>经典图片奖若干，奖金1000元（个人所得税自理）。</p>
      <div class="name">六、投稿办法</div>
      <p>上传视觉江苏网，jpg格式，单幅文件大小3-20M；</p>
      <p>稿件标题格式：千村美景+某某市某某区（县）+某某乡（镇）+某某村（社区）；</p>
      <p>总说明为该村情况简介，200字以内，单幅照片说明10-50字。无村情介绍的来稿只作为单幅稿件集纳发表，不参加大奖评选。</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      guoanList: [],
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      const thiz = this
    },
    // 投稿通道
    onRegister(type) {
      const thiz = this
      if (thiz.$rtm.store.loginStatus) {
        this.$router.push('/upPost.html')
      } else {
        this.$router.push({
          path: '/register.html',
          query: { id: type },
        })
      }
    },
    onLookPost(postId) {
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.qiancun {
  .qiancun-con {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 34.7rem);
    .title {
      text-align: center;
      margin-top: 30px;
      .one {
        font-size: 26px;
        font-weight: bold;
        line-height: 4rem;
      }
      .two,
      .three {
        font-size: 34px;
        line-height: 5rem;
        font-weight: bold;
      }
      .four {
        font-size: 26px;
        line-height: 6rem;
      }
    }
    p {
      text-align: left;
      font-size: 18px;
      line-height: 45px;
      text-indent: 2em;
    }
    .name {
      font-weight: bold;
      text-align: left;
      font-size: 18px;
      line-height: 45px;
      text-indent: 2em;
    }
  }
}
</style>
