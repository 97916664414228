<template>
  <div class="qiancun">
    <Nav></Nav>
    <div class="qiancun-con desktop-present">
      <!-- <div class="title">建设中......</div> -->
      <div class="title">
        <img src="@/assets/qiancun1.png" alt="" />
        <div class="btn">
          <!-- <div class="zhenggao">
            <a href="/activityPage.html?code=165" target="_blank"></a>
          </div> -->
          <div class="zhenggao" @click="onClickChange(1)">
            <div class="name">征稿启事</div>
          </div>

          <div class="zhenggao" @click="onFabu">
            <div class="name">强国发布</div>
          </div>

          <div class="zhenggao" @click="onClickChange(2)">
            <div class="name">来稿展示</div>
          </div>

          <div class="zhenggao">
            <a href="/catalog.html?title=千村美景" target="_blank">千村采风</a>
          </div>

          <div class="zhenggao">
            <a
              href="https://article.xuexi.cn/articles/index.html?art_id=1226254817318667041&item_id=1226254817318667041&study_style_id=feeds_opaque&pid=&ptype=-1&source=share&share_to=wx_single"
              target="_blank"
              >获奖作品</a
            >
          </div>
        </div>
      </div>
      <div class="content">
        <div class="qishi" v-if="changeType == 1">
          <QISHI></QISHI>
        </div>
        <div class="laogao" v-if="changeType == 2">
          <div class="fixtopic-con" v-for="k in fixtopicList" :key="k.id">
            <div class="fixtopic-title">
              <div class="name">{{ k.title }}</div>
              <div class="more">
                <a :href="'qiancunPost.html?id=' + k.id + '&title=' + k.title" target="_blank">>></a>
              </div>
            </div>
            <div class="fixtopic-post">
              <div class="post" v-for="p in k.posts">
                <div
                  class="img"
                  :style="{
                    backgroundImage: `url(` + p.imgURL + `)`,
                  }"
                >
                  <a :href="'/post.html?id=' + p.postId" target="_blank"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-present">
      <!-- <div class="title">建设中......</div> -->
      <div class="title">
        <img src="@/assets/qiancun.png" alt="" />
        <div class="btn">
          <!-- <div class="zhenggao">
            <a href="/activityPage.html?code=165" target="_blank"></a>
          </div> -->
          <div class="zhenggao">
            <div class="name">征稿启事</div>
          </div>

          <div class="zhenggao" @click="onFabu">
            <div class="name">强国发布</div>
          </div>

          <div class="zhenggao">
            <div class="name">来稿展示</div>
          </div>

          <div class="zhenggao">
            <a href="/" target="_blank">千村采风</a>
          </div>

          <div class="zhenggao">
            <a
              href="/https://article.xuexi.cn/articles/index.html?art_id=1226254817318667041&item_id=1226254817318667041&study_style_id=feeds_opaque&pid=&ptype=-1&source=share&share_to=wx_single"
              target="_blank"
              >获奖作品</a
            >
          </div>
        </div>
      </div>
      <div class="content">
        <div class="qishi" v-if="changeType == 1">
          <QISHI></QISHI>
        </div>
        <!-- <div class="fixtopic-con" v-for="k in fixtopicList" :key="k.id">
          <div class="fixtopic-title">
            <div class="name">{{ k.title }}</div>
            <div class="more">
              <a :href="'qiancunPost.html?id=' + k.id + '&title=' + k.title" target="_blank">>></a>
            </div>
          </div>
          <div class="fixtopic-post">
            <div class="post" v-for="p in k.posts">
              <div
                class="img"
                :style="{
                  backgroundImage: `url(` + p.imgURL + `)`,
                }"
              >
                <a :href="'/post.html?id=' + p.postId" target="_blank"></a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <el-dialog title="强国发布，请扫码" :visible.sync="strongDialogVisible" :before-close="handleClose">
      <div class="pic">
        <img src="@/assets/fabu.png" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="strongDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
import QISHI from '@/views/qishi.vue'
export default {
  components: { Nav, Bottom, QISHI },
  data: function () {
    return {
      fixtopicList: [], // 栏目的列表
      strongDialogVisible: false, // 强国发布的展示
      changeType: 1, //切换类型
    }
  },
  created() {
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      var catalogTitle = '千村美景'
      RD.index()
        .catalogByName(catalogTitle)
        .then((data) => {
          data.forEach((k) => {
            k.posts.forEach((p) => {
              p.imgURL = RD.pic().id(p.frontPicId).mImage_URL()
            })
          })
          thiz.fixtopicList = data
        })
    },
    onFabu() {
      this.strongDialogVisible = true
    },
    handleClose() {
      this.strongDialogVisible = false
    },
    // 切换其实和来稿展示
    onClickChange(type) {
      const thiz = this
      thiz.changeType = type
    },
  },
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  .mobile-present {
    min-height: calc(100vh - 32.7rem);
    position: relative;
    .title {
      width: 750px;
      margin: 20px auto;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
      }
      .btn {
        position: absolute;
        bottom: 50px;
        left: 10px;
        width: 750px;
        display: flex;
        justify-content: space-between;
        .zhenggao {
          width: 164px;
          height: 50px;
          line-height: 58px;
          font-size: 20px;
          cursor: pointer;
          background: no-repeat url(../assets/mjbg.png);
          background-size: cover;
          opacity: 0.7;
          a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            text-align: left;
            margin-left: 18px;
            color: #fff;
            display: inline-block;
          }
        }
        .zhenggao:last-child {
          opacity: 1;
        }
        .zhenggao:nth-last-of-type(2) {
          opacity: 0.9;
        }
        .zhenggao:nth-last-of-type(3) {
          opacity: 0.8;
        }
      }
    }
    .content {
      width: 120rem;
      margin: 0 auto;
      .fixtopic-con {
        height: auto;
        .fixtopic-title {
          position: relative;
          height: 50px;
          line-height: 50px;
          display: flex;
          .name {
            font-weight: bold;
            font-size: 18px;
            text-align: left;
          }
          .more {
            margin-left: 2rem;
            font-weight: bold;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              color: #000;
              text-decoration: none;
            }
          }
        }
        .fixtopic-post {
          margin: 20px 0px;
          display: flex;
          justify-content: space-between;
          .post {
            width: 175px;
            height: 130px;
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat;
              background-size: cover;
              transition: all 200ms ease-in-out;
              cursor: pointer;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
          .post:hover {
            .img {
              transform: scale(1.05);
            }
          }
          .post:nth-child(1n + 5) {
            display: none;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .qiancun-con {
    min-height: calc(100vh - 32.7rem);
    position: relative;
    .title {
      width: 120rem;
      margin: 20px auto;
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
      }
      .btn {
        position: absolute;
        bottom: 60px;
        left: 50px;
        width: 1111px;
        display: flex;
        justify-content: space-between;
        .zhenggao {
          width: 164px;
          height: 50px;
          line-height: 58px;
          font-size: 20px;
          cursor: pointer;
          background: no-repeat url(../assets/mjbg.png);
          background-size: cover;
          opacity: 0.7;
          transition: all 200ms ease-in-out;
          color: #fff;
          cursor: pointer;
          .name {
            width: 70%;
          }
          a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            text-align: left;
            margin-left: 18px;
            color: #fff;
            display: inline-block;
          }
        }
        .zhenggao:last-child {
          opacity: 1;
        }
        .zhenggao:nth-last-of-type(2) {
          opacity: 0.9;
        }
        .zhenggao:nth-last-of-type(3) {
          opacity: 0.8;
        }
        .zhenggao:hover {
          transform: scale(1.2);
        }
      }
    }
    .content {
      width: 120rem;
      margin: 0 auto;
      .fixtopic-con {
        height: auto;
        .fixtopic-title {
          position: relative;
          height: 50px;
          line-height: 50px;
          display: flex;
          .name {
            font-weight: bold;
            font-size: 18px;
            text-align: left;
          }
          .more {
            margin-left: 2rem;
            font-weight: bold;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              color: #000;
              text-decoration: none;
            }
          }
        }
        .fixtopic-post {
          margin: 20px 0px;
          display: flex;
          justify-content: space-between;
          .post {
            width: 280px;
            height: 220px;
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat;
              background-size: cover;
              transition: all 200ms ease-in-out;
              cursor: pointer;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
          .post:hover {
            .img {
              transform: scale(1.05);
            }
          }
          .post:nth-child(1n + 5) {
            display: none;
          }
        }
      }
    }
  }
  .pic {
    width: 100%;
    height: 300px;
    margin-left: 20px;
    img {
      width: 300px;
      margin: 0 auto;
      height: 100%;
      background: no-repeat 50% / cover;
    }
  }
}
</style>
